import { useContext, useState } from "react";
import {
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
  TextField,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ScheduleContext from "../../context/ScheduleContext";

const SaveTemplateModal = ({ showModal, setShowModal }) => {
  const { createTemplate } = useContext(ScheduleContext);

  const [name, setName] = useState();

  const onInputChange = (value) => {
    setName(value);
  };

  const onSave = async () => {
    await createTemplate(name);
    onCancel();
  };

  const onCancel = () => {
    setName("");
    setShowModal(false);
  };

  return (
    <Modal contentSpacing id="story-book-modal" onClose={onCancel} show={showModal} width={350}>
      <ModalHeader>
        <Heading type="h2">Save Template</Heading>
      </ModalHeader>

      <ModalContent>
        <Flex gap={20} className="modal full-width">
          <Text type="text1" ellipsis={false}>
            Name
          </Text>
          <TextField placeholder="Enter name" value={name} onChange={onInputChange} />
        </Flex>
      </ModalContent>

      <ModalFooterButtons
        onPrimaryButtonClick={onSave}
        onSecondaryButtonClick={onCancel}
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        disablePrimaryButton={!name}
      />
    </Modal>
  );
};

export default SaveTemplateModal;
