import { useState } from "react";
import {
  Flex,
  Modal,
  ModalContent,
  ModalFooterButtons,
  ModalHeader,
  Text,
  TextField,
} from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import { capitalize } from "../../utils/utils";

const AddEntityModal = ({ entityType, columnType, showModal, onAdd, onCancel }) => {
  const [entityName, setEntityName] = useState();

  const onInputChange = (value) => {
    setEntityName(value);
  };

  const onPrimaryButtonClick = async () => {
    await onAdd(entityName);
    onSecondaryButtonClick();
  };

  const onSecondaryButtonClick = () => {
    setEntityName("");
    onCancel();
  };

  const typeText = entityType === "group" ? "Group" : `${capitalize(columnType)} Column`;

  return (
    <Modal contentSpacing id="story-book-modal" onClose={onCancel} show={showModal} width={350}>
      <ModalHeader>
        <Heading type="h2">Add New {typeText}</Heading>
      </ModalHeader>

      <ModalContent>
        <Flex direction="Column" gap={20} className="modal full-width">
          <Flex justify="SpaceBetween" className="full-width">
            <Text type="text1" ellipsis={false}>
              {capitalize(entityType)} Name
            </Text>
            <TextField
              placeholder={`Enter ${entityType} name`}
              value={entityName}
              onChange={onInputChange}
            />
          </Flex>
        </Flex>
      </ModalContent>

      <ModalFooterButtons
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={onSecondaryButtonClick}
        primaryButtonText="Add"
        secondaryButtonText="Cancel"
        disablePrimaryButton={!entityName}
      />
    </Modal>
  );
};

export default AddEntityModal;
