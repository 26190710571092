import { useContext, useEffect, useRef, useState } from "react";
import { Flex, Text, Checkbox, TextField } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { populateCustomName, getNextScheduledDate } from "../../utils/utils";

const CustomNameBuilder = () => {
  const {
    itemName,
    period,
    granularity,
    selectedDays,
    start,
    customName,
    setCustomName,
    areFieldsDisabled,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);

  const textFieldRef = useRef(null);

  const variables = ["day", "date", "month", "year", "quarter", "half"];
  const selectedVariables =
    customName?.match(/\{([^{}]+)\}/g)?.map((match) => match.slice(1, -1)) || [];

  const exampleText = populateCustomName(
    customName,
    getNextScheduledDate({ period, granularity, selectedDays, start })
  );

  useEffect(() => {
    if (customName) {
      setIsFieldActive(true);
    }
  }, [customName]);

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setCustomName("");
    } else {
      setCustomName(itemName);
    }
    setIsFieldActive(e.target.checked);
  };

  const addTextAtCursor = (textToAdd) => {
    if (areFieldsDisabled) {
      return;
    }

    const textField = textFieldRef.current;
    const startPosition = textField.selectionStart;
    const endPosition = textField.selectionEnd;

    const finalText =
      customName.substring(0, startPosition) + textToAdd + customName.substring(endPosition);
    setCustomName(finalText);

    setTimeout(() => {
      textField.focus();
      textField.selectionStart = startPosition + textToAdd.length;
      textField.selectionEnd = startPosition + textToAdd.length;
    }, 0);
  };

  const onChange = (value) => {
    setCustomName(value);
  };

  return (
    <Flex direction="Column" gap={15} align="Start">
      <Flex gap={10}>
        <Checkbox
          labelClassName="checkbox-label"
          checked={isFieldActive}
          onChange={onFieldToggle}
          disabled={areFieldsDisabled}
        />

        <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
          Set Custom Date Based Name
        </Text>
      </Flex>

      {isFieldActive && (
        <Flex direction="Column" align="Start" gap={10} style={{ paddingLeft: "25px" }}>
          <TextField
            ref={textFieldRef}
            controlled={true}
            value={customName}
            onChange={onChange}
            placeholder="Create custom name"
            disabled={areFieldsDisabled}
          />

          <Flex gap={10} className="variable-container">
            {variables.map((variable) => (
              <span
                className={`variable ${selectedVariables.includes(variable) && isFieldActive && "variable-selected"} ${areFieldsDisabled && "variable-disabled"}`}
                onClick={() => addTextAtCursor(`{${variable}}`)}
              >
                {variable}
              </span>
            ))}
          </Flex>

          <Text type="text1" style={{ maxWidth: "100%" }}>
            Example Name: '{exampleText}'
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default CustomNameBuilder;
