import axios from "axios";
import { createContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import { SCHEDULE_API_URL } from "../utils/constants";

const SchedulesListContext = createContext();
const monday = mondaySdk();

export const SchedulesListProvider = ({ children }) => {
  const [sessionToken, setSessionToken] = useState("");
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [scheduleUnderDeletion, setScheduleUnderDeletion] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [message, setMessage] = useState("");
  const [isUserViewer, setIsUserViewer] = useState(false);

  useEffect(() => {
    monday.get("sessionToken").then((res) => setSessionToken(res.data));

    monday.get("context").then((res) => {
      if (!res.data) {
        return;
      }
      setIsUserViewer(res.data.user.isViewOnly);
    });
  }, []);

  const headers = { Authorization: sessionToken };

  useEffect(() => {
    const getSchedules = async () => {
      setIsLoading(true);

      let response;
      try {
        response = await axios.get(SCHEDULE_API_URL + "list", { headers });
      } catch (error) {
        console.log(error);
        setErrorMessage("Something went wrong", error.response.status);
      }

      const finalSchedules = await augmentSchedules(response.data.schedules);
      setSchedules(finalSchedules);
      setIsLoading(false);
    };

    if (sessionToken) {
      getSchedules();
    }
    // eslint-disable-next-line
  }, [sessionToken]);

  const getItems = async (itemIds) => {
    try {
      const query = `query($itemIds: [ID!]) {
        items (ids: $itemIds) {
          id
          name
          board {
            name
          }
        }
      }`;
      const variables = { itemIds };

      const response = await monday.api(query, { variables });
      return response.data.items;
    } catch (error) {
      console.error("Get Items Error:", error);
      return [];
    }
  };

  const augmentSchedules = async (schedules) => {
    const itemsMap = {};

    const itemIds = schedules.flatMap(({ firstItemId, lastItemId }) => [firstItemId, lastItemId]);
    let itemIdsToGet = [];

    for (let i = 0; i < itemIds.length; i++) {
      itemIdsToGet.push(itemIds[i]);

      if (itemIdsToGet.length === 25 || i === itemIds.length - 1) {
        const items = await getItems(itemIdsToGet);
        if (!items) {
          continue;
        }

        for (let item of items) {
          itemsMap[item.id] = { itemName: item.name, boardName: item.board.name };
        }
        itemIdsToGet = [];
      }
    }

    const finalSchedules = [];
    for (let schedule of schedules) {
      const { firstItemId, lastItemId } = schedule;
      if (firstItemId in itemsMap) {
        finalSchedules.push({ ...schedule, ...itemsMap[firstItemId] });
      } else if (lastItemId in itemsMap) {
        finalSchedules.push({ ...schedule, ...itemsMap[lastItemId] });
      } else {
        finalSchedules.push({ ...schedule, itemNotFound: true });
      }
    }

    finalSchedules.sort((schedule1, schedule2) => {
      // Either both boardName and itemName are present, or none of them are present.
      const boardNameKey = "boardName";
      if (boardNameKey in schedule1 && boardNameKey in schedule2) {
        const boardNameComparison = schedule1.boardName.localeCompare(schedule2.boardName);
        if (boardNameComparison !== 0) {
          return boardNameComparison;
        }
        return schedule1.itemName.localeCompare(schedule2.itemName);
      }

      return boardNameKey in schedule1 ? -1 : boardNameKey in schedule2 ? 1 : 0;
    });

    return finalSchedules;
  };

  const setErrorMessage = (prefixMessage, statusCode) => {
    if (statusCode === 400) {
      setMessage(`${prefixMessage}! Invalid data provided`);
    } else if (statusCode === 401) {
      setMessage(`${prefixMessage}! Authorization error`);
    } else {
      setMessage(`${prefixMessage}! Please try again or contact app support`);
    }
  };

  const deleteSchedule = async (schedule) => {
    const {
      scheduleId,
      boardId,
      itemId,
      action: { indicatorColumnId },
    } = schedule;

    setIsDeleting(true);
    setScheduleUnderDeletion(scheduleId);
    try {
      await axios.delete(SCHEDULE_API_URL + scheduleId, { headers });
      setMessage("Schedule deleted successfully");
      setSchedules(schedules.filter((schedule) => schedule.scheduleId !== scheduleId));

      if (boardId && itemId && indicatorColumnId) {
        const query = `mutation ($itemId: ID!, $boardId: ID!, $indicatorColumnId: String!, $value: String!) {
          change_simple_column_value (item_id: $itemId, board_id: $boardId, column_id: $indicatorColumnId, value: $value, create_labels_if_missing: true) {
            id
          }
        }`;
        const variables = { itemId, boardId, indicatorColumnId, value: "Deleted" };

        try {
          await monday.api(query, { variables });
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      setErrorMessage("Schedule deletion failed", 500);
    } finally {
      setIsDeleting(false);
      setScheduleUnderDeletion("");
    }
  };

  return (
    <SchedulesListContext.Provider
      value={{
        schedules,
        isLoading,
        scheduleUnderDeletion,
        isDeleting,
        message,
        setMessage,
        isUserViewer,
        deleteSchedule,
      }}
    >
      {children}
    </SchedulesListContext.Provider>
  );
};

export default SchedulesListContext;
