import { useContext, useEffect, useMemo, useState } from "react";
import { Flex, Text, Dropdown, Checkbox } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";
import { TARGET_GROUP_FIELD } from "../../utils/constants";
import { updateMissingFields } from "../../utils/utils";
import AddEntityModal from "./AddEntityModal";

const TargetGroupSelector = () => {
  const {
    groups,
    targetGroupId,
    setTargetGroupId,
    missingFields,
    setMissingFields,
    areFieldsDisabled,
    showAddGroupModal,
    setShowAddGroupModal,
    setGroupAddCallback,
    addNewGroup,
  } = useContext(ScheduleContext);

  const [isFieldActive, setIsFieldActive] = useState(false);

  const groupOptions = useMemo(
    () => [
      ...groups.map(({ id, title }) => ({ value: id, label: title })),
      { value: "add_group", label: "+ Add group" },
    ],
    [groups]
  );

  useEffect(() => {
    if (targetGroupId) {
      setIsFieldActive(true);
    }

    updateMissingFields(
      TARGET_GROUP_FIELD,
      targetGroupId,
      groupOptions.map(({ value }) => value),
      missingFields,
      setMissingFields
    );
  }, [targetGroupId, groupOptions, missingFields, setMissingFields]);

  const selectedOption = targetGroupId
    ? groupOptions.filter((option) => option.value === targetGroupId)[0]
    : null;

  const onFieldToggle = (e) => {
    if (!e.target.checked) {
      setTargetGroupId("");
    }
    setIsFieldActive(e.target.checked);
  };

  const onGroupChange = (option) => {
    if (option?.value === "add_group") {
      setShowAddGroupModal(true);
      setGroupAddCallback(() => (option) => onGroupChange(option));
    } else {
      setTargetGroupId(option ? option.value : "");
      if (!option) {
        setIsFieldActive(false);
      }
    }
  };

  const onModalCancel = () => {
    setShowAddGroupModal(false);
  };

  return (
    <Flex gap={10}>
      <Checkbox
        labelClassName="checkbox-label"
        checked={isFieldActive}
        onChange={onFieldToggle}
        disabled={areFieldsDisabled}
      />

      <Text type="text1" color={isFieldActive ? "primary" : "secondary"}>
        Move to Group
      </Text>

      <div className="target-group">
        <Dropdown
          options={groupOptions}
          size="small"
          value={selectedOption}
          onChange={onGroupChange}
          disabled={!isFieldActive || areFieldsDisabled}
        />
      </div>

      <AddEntityModal
        entityType="group"
        showModal={showAddGroupModal}
        onAdd={addNewGroup}
        onCancel={onModalCancel}
      />
    </Flex>
  );
};

export default TargetGroupSelector;
