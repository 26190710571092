import { useContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import { Flex, Loader, ThemeProvider, Text, Link } from "monday-ui-react-core";
import MyWeekIcon from "monday-ui-react-core/dist/icons/MyWeek";
import RecurringIcon from "monday-ui-react-core/dist/icons/Recurring";
import { Heading } from "monday-ui-react-core/next";
import "monday-ui-react-core/tokens";
import MessageToast from "../components/common/MessageToast";
import AddColumnModal from "../components/item/AddColumnModal";
import ButtonGroup from "../components/item/ButtonGroup";
import CustomNameBuilder from "../components/item/CustomNameBuilder";
import DayFields from "../components/item/DayFields";
import DeleteTemplateModal from "../components/item/DeleteTemplateModal";
import DueDateSelector from "../components/item/DueDateSelector";
import DuplicateItemOption from "../components/item/DuplicateItemOption";
import IndicatorColumnSelector from "../components/item/IndicatorColumnSelector";
import MissingFieldsWarning from "../components/item/MissingFieldsWarning";
import MonthFields from "../components/item/MonthFields";
import NextScheduledDate from "../components/item/NextScheduledDate";
import NotificationOption from "../components/item/NotificationOptionSelector";
import OAuthScreen from "../components/item/OAuthScreen";
import PeriodSelector from "../components/item/PeriodSelector";
import RecurrenceVisualizer from "../components/item/RecurrenceVisualizer";
import SaveTemplateModal from "../components/item/SaveTemplateModal";
import ScheduleStatus from "../components/item/ScheduleStatus";
import StatusSelector from "../components/item/StatusSelector";
import SubscribeNudge from "../components/item/SubscribeNudge";
import TargetGroupSelector from "../components/item/TargetGroupSelector";
import TemplateMenu from "../components/item/TemplateMenu";
import TimezoneSelector from "../components/item/TimezoneSelector";
import ValidationError from "../components/item/ValidationError";
import WeekFields from "../components/item/WeekFields";
import YearFields from "../components/item/YearFields";
import ScheduleContext from "../context/ScheduleContext";

const monday = mondaySdk();

const ItemView = () => {
  const {
    isOAuthComplete,
    isSubitem,
    isScheduleLoading,
    granularity,
    message,
    setMessage,
    isInvokedFromList,
  } = useContext(ScheduleContext);

  const [context, setContext] = useState({});
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);

  useEffect(() => {
    monday.listen("context", (res) => {
      setContext((previousContext) => (previousContext === res.data ? previousContext : res.data));
    });
  }, []);

  return (
    <div className={`${context.theme}-app-theme item-view`}>
      <ThemeProvider theme={context.themeConfig} className="item-view">
        {isScheduleLoading ? (
          <Loader color="var(--secondary-text-color)" size={64} />
        ) : (
          <>
            {!isOAuthComplete ? (
              <OAuthScreen />
            ) : (
              <>
                <ScheduleStatus />

                <MissingFieldsWarning />

                {!isInvokedFromList && (
                  <>
                    <Flex className="header">
                      <Flex gap={10}>
                        <Heading weight="light" type="h2">
                          Set Schedule
                        </Heading>
                        <MyWeekIcon size={30} />
                      </Flex>
                      <TemplateMenu
                        setShowSaveTemplateModal={setShowSaveTemplateModal}
                        setShowDeleteTemplateModal={setShowDeleteTemplateModal}
                      />
                    </Flex>
                    <SaveTemplateModal
                      showModal={showSaveTemplateModal}
                      setShowModal={setShowSaveTemplateModal}
                    />
                    <DeleteTemplateModal
                      showModal={showDeleteTemplateModal}
                      setShowModal={setShowDeleteTemplateModal}
                    />
                  </>
                )}

                <PeriodSelector />

                {granularity === "day" && <DayFields />}
                {granularity === "week" && <WeekFields />}
                {granularity === "month" && <MonthFields />}
                {granularity === "year" && <YearFields />}

                <TimezoneSelector />

                <Flex gap={20}>
                  <RecurrenceVisualizer />
                  <NextScheduledDate />
                </Flex>

                <Flex gap={10} align="Center" style={{ margin: "1rem 0 0.5rem" }}>
                  <RecurringIcon size={20} />
                  <Heading weight="light" type="h3">
                    On every scheduled date
                  </Heading>
                </Flex>

                <DuplicateItemOption />
                {!isSubitem && <TargetGroupSelector />}
                <StatusSelector />
                <DueDateSelector />
                <NotificationOption />
                <CustomNameBuilder />
                <IndicatorColumnSelector />

                <ValidationError />
                <SubscribeNudge />

                <ButtonGroup />
                <AddColumnModal />
              </>
            )}

            {!isInvokedFromList && (
              <Text align="center" type="text2">
                Trying this app for the first time? Checkout our{" "}
                <Link
                  href="https://superworkday.com/schedule-recurring-tasks/how-to-use.html"
                  text="how to use guide"
                  inheritFontSize
                  inlineText
                />
              </Text>
            )}
          </>
        )}

        <MessageToast message={message} setMessage={setMessage} />
      </ThemeProvider>
    </div>
  );
};

export default ItemView;
