import { useContext } from "react";
import { Modal, ModalContent, ModalFooterButtons, ModalHeader, Text } from "monday-ui-react-core";
import { Heading } from "monday-ui-react-core/next";
import ScheduleContext from "../../context/ScheduleContext";

const DeleteTemplateModal = ({ showModal, setShowModal }) => {
  const { templateToDelete, deleteTemplate } = useContext(ScheduleContext);

  const onDelete = async () => {
    await deleteTemplate();
    setShowModal(false);
  };

  const onCancel = () => {
    setShowModal(false);
  };

  return (
    <Modal contentSpacing id="story-book-modal" onClose={onCancel} show={showModal} width={350}>
      <ModalHeader>
        <Heading type="h2">Delete Template</Heading>
      </ModalHeader>

      <ModalContent>
        <Text type="text1" ellipsis={false}>
          Are you sure you want to delete the template "{templateToDelete?.name}"?
        </Text>
      </ModalContent>

      <ModalFooterButtons
        onPrimaryButtonClick={onDelete}
        onSecondaryButtonClick={onCancel}
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
      />
    </Modal>
  );
};

export default DeleteTemplateModal;
