import axios from "axios";
import { decodeJwt } from "jose";
import { createContext, useEffect, useState } from "react";
import mondaySdk from "monday-sdk-js";
import {
  TARGET_GROUP_FIELD,
  STATUS_COLUMN_FIELD,
  STATUS_VALUE_FIELD,
  DUE_DATE_COLUMN_FIELD,
  BASE_API_URL,
  SCHEDULE_API_URL,
  TEMPLATE_API_URL,
} from "../utils/constants";
import { capitalize } from "../utils/utils";

const ScheduleContext = createContext();
const monday = mondaySdk();

export const ScheduleProvider = ({
  isInvokedFromList,
  inputBoardId,
  inputItemId,
  setScheduleEdited,
  children,
}) => {
  const [isOAuthComplete, setIsOAuthComplete] = useState(true);
  const [oauthFlowInitiated, setOAuthFlowInitiated] = useState(false);
  const [sessionToken, setSessionToken] = useState("");
  const [accountId, setAccountId] = useState("");
  const [boardId, setBoardId] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemName, setItemName] = useState("");
  const [isSubitem, setIsSubitem] = useState(false);
  const [subscription, setSubscription] = useState(undefined);
  const [groups, setGroups] = useState([]);
  const [columns, setColumns] = useState([]);
  const [timezones, setTimezones] = useState([]);
  const [isScheduleLoading, setIsScheduleLoading] = useState(true);
  const [scheduleId, setScheduleId] = useState("");
  const [period, setPeriod] = useState(1);
  const [granularity, setGranularity] = useState("day");
  const [selectedDays, setSelectedDays] = useState([]);
  const [start, setStart] = useState("");
  const [timezone, setTimezone] = useState("Pacific Time (US \u0026 Canada)");
  const [duplicateItem, setDuplicateItem] = useState(true);
  const [withUpdates, setWithUpdates] = useState(false);
  const [useLatestItem, setUseLatestItem] = useState(false);
  const [targetGroupId, setTargetGroupId] = useState("");
  const [statusColumnId, setStatusColumnId] = useState("");
  const [statusValue, setStatusValue] = useState("");
  const [dueDateColumnId, setDueDateColumnId] = useState("");
  const [duePostDays, setDuePostDays] = useState(0);
  const [notificationEnabled, setNotificationEnabled] = useState(true);
  const [peopleColumnId, setPeopleColumnId] = useState("");
  const [customName, setCustomName] = useState("");
  const [indicatorColumnId, setIndicatorColumnId] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [message, setMessage] = useState("");
  const [missingFields, setMissingFields] = useState({
    [TARGET_GROUP_FIELD]: false,
    [STATUS_COLUMN_FIELD]: false,
    [STATUS_VALUE_FIELD]: false,
    [DUE_DATE_COLUMN_FIELD]: false,
  });
  const [isUserViewer, setIsUserViewer] = useState(false);
  const [areFieldsDisabled, setAreFieldsDisabled] = useState(false);
  const [showSubscribeNudge, setShowSubscribeNudge] = useState(false);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [groupAddCallback, setGroupAddCallback] = useState(() => () => {});
  const [columnTypeToAdd, setColumnTypeToAdd] = useState("");
  const [columnAddCallback, setColumnAddCallback] = useState(() => () => {});
  const [templates, setTemplates] = useState([]);
  const [templateToDelete, setTemplateToDelete] = useState();

  useEffect(() => {
    monday.get("sessionToken").then((res) => setSessionToken(res.data));

    monday.get("context").then(async (res) => {
      if (!res.data) {
        return;
      }

      const {
        account,
        user: { isViewOnly },
      } = res.data;
      const boardId = inputBoardId ?? res.data.boardId;
      const itemId = inputItemId ?? res.data.itemId;

      setBoardId(boardId);
      setItemId(itemId);
      setAccountId(account.id);
      setIsUserViewer(isViewOnly);

      // After introduction of a free plan, this will always hold a valid value. But still keeping
      // the if condition just in case if the free plan is removed sometime in future.
      if (res.data.subscription) {
        setSubscription(res.data.subscription);
      }

      try {
        const query = `query ($itemId: ID!) {
          items (ids: [$itemId]) {
            name
            parent_item {
              id
            }
          }
        }`;
        const response = await monday.api(query, { variables: { itemId } });
        const item = response.data.items[0];
        setItemName(item.name);
        setIsSubitem(item.parent_item !== null);
      } catch (error) {
        console.error("Get Item error:", error.message);
      }

      try {
        const query = `query ($boardId: ID!) {
          boards (ids: [$boardId]) {
            groups {
              id
              title
            }
            columns {
              id
              title
              type
              settings_str
            }
          }
        }`;
        const response = await monday.api(query, { variables: { boardId } });
        const board = response.data.boards[0];
        setGroups(board.groups);
        setColumns(board.columns);
      } catch (error) {
        console.error("Get Columns error:", error.message);
      }
    });
  }, [inputBoardId, inputItemId]);

  const headers = { Authorization: sessionToken };

  useEffect(() => {
    const getTimezones = async () => {
      try {
        const response = await axios.get(BASE_API_URL + "timezones");
        setTimezones(response.data);
      } catch (error) {
        console.log(error);
        setErrorMessage("Something went wrong", error.status);
      }
    };

    getTimezones();
  }, []);

  useEffect(() => {
    const getExistingSchedule = async () => {
      setIsScheduleLoading(true);

      let response;
      try {
        response = await axios.get(SCHEDULE_API_URL + itemId, { headers });
        setIsScheduleLoading(false);
      } catch (error) {
        console.log(error);
        const data = error.response.data;

        if (data?.error?.includes("OAuth")) {
          setIsOAuthComplete(false);
          setIsScheduleLoading(false);
        } else {
          setErrorMessage("Something went wrong", error.response.status);
        }
        return;
      }

      if (response.data.constructor !== Object || !Object.keys(response.data).length) {
        // No schedule has been ever configured for this item.
        return;
      }

      const {
        scheduleId,
        recurrence: { period, granularity, selectedDays, start },
        timezone,
        action: {
          duplicateItem,
          withUpdates,
          useLatestItem,
          targetGroupId,
          statusColumnId,
          statusValue,
          dueDateColumnId,
          duePostDays,
          notificationEnabled,
          peopleColumnId,
          customName,
          indicatorColumnId,
        },
        isActive,
      } = response.data;

      setScheduleId(scheduleId);
      setPeriod(period);
      setGranularity(granularity);
      setSelectedDays(selectedDays);
      setStart(start);
      setTimezone(timezone);
      setDuplicateItem(duplicateItem);
      setWithUpdates(withUpdates);
      setUseLatestItem(useLatestItem);
      setTargetGroupId(targetGroupId);
      setStatusColumnId(statusColumnId);
      setStatusValue(statusValue);
      setDueDateColumnId(dueDateColumnId);
      setDuePostDays(duePostDays);
      setNotificationEnabled(notificationEnabled);
      setPeopleColumnId(peopleColumnId);
      setCustomName(customName);
      setIndicatorColumnId(indicatorColumnId);
      setIsActive(isActive);
    };

    if (itemId) {
      getExistingSchedule();
    }
    // eslint-disable-next-line
  }, [itemId, sessionToken]);

  useEffect(() => {
    const getTemplates = async () => {
      const response = await axios.get(TEMPLATE_API_URL + "/list", { headers });
      setTemplates(response.data.templates ?? []);
    };

    if (sessionToken) {
      getTemplates();
    }
    // eslint-disable-next-line
  }, [sessionToken]);

  useEffect(() => {
    setAreFieldsDisabled(isLoading || isUserViewer || (scheduleId && !inEditMode));
  }, [isLoading, isUserViewer, scheduleId, inEditMode]);

  const startOAuthFlow = async (invokedByUser) => {
    await axios.post(
      BASE_API_URL + "log/",
      {
        oAuthFlowInvokedByUser: invokedByUser,
        isElectronApp: window.navigator?.userAgent?.includes("Electron"),
      },
      { headers }
    );

    const clientId = "813af5547017c7690503108da29dd4ba";
    const redirectUri = "https://" + window.location.host + "/api/auth";
    const slug = decodeJwt(sessionToken).dat.slug;

    setOAuthFlowInitiated(true);
    monday.execute("openLinkInTab", {
      url: `https://auth.monday.com/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&subdomain=${slug}`,
    });
  };

  const setErrorMessage = (prefixMessage, statusCode) => {
    if (statusCode === 400) {
      setMessage(`${prefixMessage}! Invalid data provided`);
    } else if (statusCode === 401) {
      setMessage(`${prefixMessage}! Authorization error`);
    } else {
      setMessage(`${prefixMessage}! Please try again or contact app support`);
    }
  };

  const vaidateCreateUpdateRequest = () => {
    if (statusColumnId && !statusValue) {
      setValidationError("Please select a status value");
      return false;
    }

    if (Object.values(missingFields).some((value) => value)) {
      setValidationError("Please fix the missing field values");
      return false;
    }

    setValidationError("");
    return true;
  };

  const validateSubscription = () => {
    if (!subscription) {
      monday.execute("openPlanSelection", { isInPlanSelection: true });
      setShowSubscribeNudge(true);
      return false;
    }
    return true;
  };

  const handleError = (error, prefixMessage) => {
    console.log(error);
    const data = error.response.data;

    if (data?.error?.includes("plan")) {
      setShowSubscribeNudge(true);
    } else {
      setErrorMessage(prefixMessage, error.response.status);
    }
  };

  const updateIndicatorColumn = async (label) => {
    if (!indicatorColumnId) {
      return;
    }

    let value = label;
    if (!value) {
      if (granularity === "month") {
        if (period === 3) {
          value = "Quarter";
        } else if (period === 6) {
          value = "Semi-Annual";
        }
      }
      value = value || capitalize(granularity);
    }

    const query = `mutation ($itemId: ID!, $boardId: ID!, $indicatorColumnId: String!, $value: String!) {
      change_simple_column_value (item_id: $itemId, board_id: $boardId, column_id: $indicatorColumnId, value: $value, create_labels_if_missing: true) {
        id
      }
    }`;
    const variables = { itemId, boardId, indicatorColumnId, value };

    try {
      await monday.api(query, { variables });
    } catch (error) {
      console.log(error);
    }
  };

  const createSchedule = async () => {
    if (!vaidateCreateUpdateRequest() || !validateSubscription()) {
      return;
    }

    const body = {
      boardId,
      itemId,
      recurrence: { granularity, period, selectedDays, start },
      timezone,
      action: {
        duplicateItem,
        withUpdates,
        useLatestItem,
        targetGroupId,
        statusColumnId,
        statusValue,
        dueDateColumnId,
        duePostDays,
        notificationEnabled,
        peopleColumnId,
        customName,
        indicatorColumnId,
      },
    };

    setIsLoading(true);
    try {
      const response = await axios.post(SCHEDULE_API_URL, body, { headers });
      setScheduleId(response.data.scheduleId);
      setIsActive(true);
      setMessage("Task scheduled successfully");

      await updateIndicatorColumn();
      monday.execute("valueCreatedForUser");
    } catch (error) {
      handleError(error, "Task scheduling failed");
    } finally {
      setIsLoading(false);
    }
  };

  const updateSchedule = async () => {
    if (!vaidateCreateUpdateRequest() || !validateSubscription()) {
      return;
    }

    const body = {
      scheduleId,
      boardId,
      itemId,
      recurrence: { granularity, period, selectedDays, start },
      timezone,
      action: {
        duplicateItem,
        withUpdates,
        useLatestItem,
        targetGroupId,
        statusColumnId,
        statusValue,
        dueDateColumnId,
        duePostDays,
        notificationEnabled,
        peopleColumnId,
        customName,
        indicatorColumnId,
      },
    };

    setIsLoading(true);
    try {
      await axios.put(SCHEDULE_API_URL, body, { headers });
      setInEditMode(false);
      setMessage("Schedule updated successfully");

      await updateIndicatorColumn();
      if (isInvokedFromList) {
        setScheduleEdited(true);
      }
    } catch (error) {
      handleError(error, "Schedule update failed");
    } finally {
      setIsLoading(false);
    }
  };

  const unschedule = async () => {
    const body = { scheduleId, isActive: false };

    setIsLoading(true);
    try {
      await axios.put(SCHEDULE_API_URL, body, { headers });
      setIsActive(false);
      setMessage("Task unscheduled successfully");

      await updateIndicatorColumn("Unscheduled");
      if (isInvokedFromList) {
        setScheduleEdited(true);
      }
    } catch (error) {
      handleError(error, "Task unscheduling failed");
    } finally {
      setIsLoading(false);
    }
  };

  const reschedule = async () => {
    if (!validateSubscription()) {
      return;
    }

    const body = { scheduleId, isActive: true };

    setIsLoading(true);
    try {
      await axios.put(SCHEDULE_API_URL, body, { headers });
      setIsActive(true);
      setMessage("Task rescheduled successfully");

      await updateIndicatorColumn();
      if (isInvokedFromList) {
        setScheduleEdited(true);
      }
    } catch (error) {
      handleError(error, "Task rescheduling failed");
    } finally {
      setIsLoading(false);
    }
  };

  const deleteSchedule = async () => {
    setIsLoading(true);
    try {
      await axios.delete(SCHEDULE_API_URL + scheduleId, { headers });
      setScheduleId("");
      setIsActive(false);
      setMessage("Schedule deleted successfully");

      await updateIndicatorColumn("Deleted");
      if (isInvokedFromList) {
        setScheduleEdited(true);
      }
    } catch (error) {
      handleError(error, "Schedule deletion failed");
    } finally {
      setIsLoading(false);
    }
  };

  const addNewGroup = async (name) => {
    const query = `mutation ($boardId: ID!, $groupName: String!) {
      create_group (board_id: $boardId, group_name: $groupName, position_relative_method: before_at) {
        id
        title
      }
    }`;
    const variables = { boardId, groupName: name };

    try {
      const response = await monday.api(query, { variables });
      const group = response.data.create_group;

      setGroups([...groups, group]);
      groupAddCallback({ value: group.id, label: group.title });
    } catch (error) {
      console.error("Create Group error:", error.message);
    } finally {
      setShowAddGroupModal(false);
    }
  };

  const addNewColumn = async (name) => {
    const query = `mutation ($boardId: ID!, $title: String!, $columnType: ColumnType!) {
      create_column (board_id: $boardId, title: $title, column_type: $columnType) {
        id
        type
        title
        settings_str
      }
    }`;
    const variables = { boardId, title: name, columnType: columnTypeToAdd };

    try {
      const response = await monday.api(query, { variables });
      const column = response.data.create_column;

      setColumns([...columns, column]);
      columnAddCallback({
        value: column.id,
        label: column.title,
        possibleValues: JSON.parse(column.settings_str)?.labels,
      });
    } catch (error) {
      console.error("Create Column error:", error.message);
    } finally {
      setColumnTypeToAdd("");
    }
  };

  const createTemplate = async (name) => {
    const findColumn = (id) => columns.find((column) => column.id === id);

    let statusValueLabel;
    if (statusColumnId) {
      const statusColumn = findColumn(statusColumnId);
      statusValueLabel = JSON.parse(statusColumn?.settings_str)?.labels?.[statusValue];
    }

    const template = {
      name,
      data: {
        recurrence: { period, granularity, selectedDays, start },
        timezone,
        action: {
          duplicateItem,
          withUpdates,
          useLatestItem,
          targetGroup: groups.find((group) => group.id === targetGroupId)?.title,
          statusColumn: findColumn(statusColumnId)?.title,
          statusValueLabel,
          dueDateColumn: findColumn(dueDateColumnId)?.title,
          duePostDays,
          notificationEnabled,
          peopleColumn: findColumn(peopleColumnId)?.title,
          customName,
          indicatorColumn: findColumn(indicatorColumnId)?.title,
        },
      },
    };

    setIsLoading(true);
    try {
      const response = await axios.post(TEMPLATE_API_URL, template, { headers });
      template.templateId = response.data.templateId;

      setTemplates((templates) => [...templates, template]);
      setMessage("Template saved successfully");
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.error?.includes("already exists")) {
        setMessage("Template with this name already exists");
      } else {
        setMessage("Template creation failed");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const loadTemplate = async (templateId) => {
    const template = templates.find((template) => template.templateId === templateId);
    if (!template) {
      return;
    }

    const { recurrence, timezone, action } = template.data;

    setPeriod(recurrence.period);
    setGranularity(recurrence.granularity);
    setSelectedDays(recurrence.selectedDays);
    setStart(recurrence.start);
    setTimezone(timezone);
    setDuplicateItem(action.duplicateItem);
    setWithUpdates(action.withUpdates);
    setUseLatestItem(action.useLatestItem);

    const group = groups.find((group) => group.title === action.targetGroup);
    setTargetGroupId(group?.id || "");

    const statusColumn = columns.find((column) => column.title === action.statusColumn);
    if (statusColumn) {
      setStatusColumnId(statusColumn.id);
      const labels = JSON.parse(statusColumn?.settings_str)?.labels || {};

      const statusValueIdx = Object.entries(labels).find(
        ([_, value]) => value === action.statusValueLabel
      )?.[0];
      setStatusValue(statusValueIdx || "");
    } else {
      setStatusColumnId("");
      setStatusValue("");
    }

    const dueDateColumn = columns.find((column) => column.title === action.dueDateColumn);
    if (dueDateColumn) {
      setDueDateColumnId(dueDateColumn.id);
      setDuePostDays(action.duePostDays);
    } else {
      setDueDateColumnId("");
      setDuePostDays(0);
    }

    setNotificationEnabled(action.notificationEnabled);
    const peopleColumn = columns.find((column) => column.title === action.peopleColumn);
    setPeopleColumnId(peopleColumn?.id || "");

    setCustomName(action.customName);

    const indicatorColumn = columns.find((column) => column.title === action.indicatorColumn);
    setIndicatorColumnId(indicatorColumn?.id || "");
  };

  const deleteTemplate = async () => {
    if (!templateToDelete) {
      return;
    }

    setIsLoading(true);
    try {
      await axios.delete(TEMPLATE_API_URL + templateToDelete.templateId, { headers });
      setTemplates((templates) =>
        templates.filter((template) => template.templateId !== templateToDelete.templateId)
      );
      setMessage("Template deleted successfully");
      setTemplateToDelete(undefined);
    } catch (error) {
      handleError(error, "Template deletion failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ScheduleContext.Provider
      value={{
        isOAuthComplete,
        oauthFlowInitiated,
        setOAuthFlowInitiated,
        startOAuthFlow,
        accountId,
        boardId,
        itemName,
        isSubitem,
        groups,
        columns,
        timezones,
        isScheduleLoading,
        scheduleId,
        period,
        setPeriod,
        granularity,
        setGranularity,
        selectedDays,
        setSelectedDays,
        start,
        setStart,
        timezone,
        setTimezone,
        duplicateItem,
        setDuplicateItem,
        withUpdates,
        setWithUpdates,
        useLatestItem,
        setUseLatestItem,
        targetGroupId,
        setTargetGroupId,
        statusColumnId,
        setStatusColumnId,
        statusValue,
        setStatusValue,
        dueDateColumnId,
        setDueDateColumnId,
        duePostDays,
        setDuePostDays,
        notificationEnabled,
        setNotificationEnabled,
        peopleColumnId,
        setPeopleColumnId,
        customName,
        setCustomName,
        indicatorColumnId,
        setIndicatorColumnId,
        isActive,
        isLoading,
        inEditMode,
        setInEditMode,
        validationError,
        setValidationError,
        message,
        setMessage,
        missingFields,
        setMissingFields,
        isUserViewer,
        areFieldsDisabled,
        showSubscribeNudge,
        showAddGroupModal,
        setShowAddGroupModal,
        setGroupAddCallback,
        columnTypeToAdd,
        setColumnTypeToAdd,
        setColumnAddCallback,
        templates,
        templateToDelete,
        setTemplateToDelete,
        createSchedule,
        updateSchedule,
        unschedule,
        reschedule,
        deleteSchedule,
        isInvokedFromList,
        addNewGroup,
        addNewColumn,
        createTemplate,
        loadTemplate,
        deleteTemplate,
      }}
    >
      {children}
    </ScheduleContext.Provider>
  );
};

export default ScheduleContext;
