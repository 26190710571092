import { useContext } from "react";
import { MenuButton, Menu, MenuItem, MenuTitle } from "monday-ui-react-core";
import ScheduleContext from "../../context/ScheduleContext";

const TemplateMenu = ({ setShowSaveTemplateModal, setShowDeleteTemplateModal }) => {
  const { scheduleId, inEditMode, templates, loadTemplate, setTemplateToDelete } =
    useContext(ScheduleContext);

  const showSaveMenuItem = scheduleId && !inEditMode;
  const showTemplatesAsList = !showSaveMenuItem && templates.length <= 10;
  const showTemplatesAsSubmenu = !showSaveMenuItem && templates.length > 10;

  return (
    <MenuButton size="xs" className="menu">
      <Menu id="menu" size={Menu.sizes.MEDIUM}>
        {showSaveMenuItem && (
          <MenuItem title="Save as template" onClick={() => setShowSaveTemplateModal(true)} />
        )}

        {/* This needs to be done this way as Menu does not handle fragment properly */}
        {showTemplatesAsList && <MenuTitle caption="Load template" />}
        {showTemplatesAsList &&
          templates.map(({ templateId, name }) => (
            <MenuItem
              key={templateId}
              title={name}
              onClick={() => loadTemplate(templateId)}
              className="flattened-menu-item"
            />
          ))}

        {showTemplatesAsSubmenu && (
          <MenuItem title="Load template">
            <Menu id="menu" size={Menu.sizes.MEDIUM}>
              {templates.map(({ templateId, name }) => (
                <MenuItem key={templateId} title={name} onClick={() => loadTemplate(templateId)} />
              ))}
            </Menu>
          </MenuItem>
        )}

        <MenuItem title="Delete template">
          <Menu id="menu" size={Menu.sizes.MEDIUM}>
            {templates.map((template) => (
              <MenuItem
                key={template.templateId}
                title={template.name}
                onClick={() => {
                  setTemplateToDelete(template);
                  setShowDeleteTemplateModal(true);
                }}
              />
            ))}
          </Menu>
        </MenuItem>
      </Menu>
    </MenuButton>
  );
};

export default TemplateMenu;
